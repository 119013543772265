// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ai-green-Ins1 {
  position: relative;
  top: 0;
  left: 0;
}

.init-ai-sectionIns1 {
  position: absolute;
  top: 46%;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  color: white;
  font-weight: 900;
}

/* Default font size for larger screens */
.init-ai-sectionIns1:nth-child(1) {
  font-size: 3rem;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .init-ai-sectionIns1:nth-child(1) {
    font-size: 1rem; /* Adjust this value as needed */
  }
}
.init-red-bg {
  background: red;
}

.Ins1 {
  padding-right: 42%;
}

.Indusfirst li {
  padding-left: 35%;
  margin: 1%;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Industries/style.scss"],"names":[],"mappings":"AACA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,QAAA;EACA,QAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AAAJ;;AAGA,yCAAA;AACA;EACI,eAAA;AAAJ;;AAGA,mCAAA;AACA;EACI;IACI,eAAA,EAAA,gCAAA;EAAN;AACF;AAGA;EACI,eAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;;AAIA;EACI,iBAAA;EACA,UAAA;EACA,eAAA;AADJ","sourcesContent":["\n.ai-green-Ins1 {\n    position: relative;\n    top: 0;\n    left: 0;\n}\n\n.init-ai-sectionIns1 {\n    position: absolute;\n    top: 46%;\n    left: 0%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    width: 100%;\n    color: white;\n    font-weight: 900;\n}\n\n/* Default font size for larger screens */\n.init-ai-sectionIns1:nth-child(1) {\n    font-size: 3rem;\n}\n\n/* Media query for mobile devices */\n@media (max-width: 768px) {\n    .init-ai-sectionIns1:nth-child(1) {\n        font-size: 1rem; /* Adjust this value as needed */\n    }\n}\n\n.init-red-bg {\n    background: red;\n}\n\n.Ins1{\n    padding-right: 42%;\n}\n\n.Indusfirst li{\n    padding-left: 35%;\n    margin: 1%;\n    font-size: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
