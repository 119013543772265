import React from "react";
import "./App.scss";
import Main from "./routes";

function App() {
  return (
    <div>
      <Main/>
    </div>
  );
}

export default App;

