// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
header {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* For transparent navbar */
.navbar-transparent {
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); /* Adjust the blur as needed */
  box-shadow: none;
}

/* For solid navbar */
.navbar-solid {
  background: white;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for the solid state */
}

.navbar-solid,
.navbar-transparent {
  position: fixed; /* Make sure the navbar stays on top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it’s above other content */
}

.navbar-transparent {
  position: absolute; /* Allows it to overlap with other content in the background */
}

/* Add some padding for better spacing */
header nav {
  padding: 10px 20px; /* Adjust padding as needed */
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/common/navbar/styles.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,4DAAA;AAEJ;;AACE,2BAAA;AACA;EACE,oCAAA,EAAA,2BAAA;EACA,mCAAA;UAAA,2BAAA,EAAA,8BAAA;EACA,gBAAA;AAEJ;;AACE,qBAAA;AACA;EACE,iBAAA;EACA,6BAAA;UAAA,qBAAA;EACA,wCAAA,EAAA,oCAAA;AAEJ;;AACE;;EAEE,eAAA,EAAA,sCAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA,EAAA,oCAAA;AAEJ;;AACE;EACE,kBAAA,EAAA,8DAAA;AAEJ;;AACE,wCAAA;AACA;EACE,kBAAA,EAAA,6BAAA;AAEJ;;AACE;EACE,SAAA;EACA,UAAA;EACA,gBAAA;AAEJ;;AACE;EACE,qBAAA;AAEJ","sourcesContent":["header {\n    transition: background-color 0.3s ease, box-shadow 0.3s ease;\n  }\n  \n  /* For transparent navbar */\n  .navbar-transparent {\n    background: rgba(255, 255, 255, 0.5); /* Semi-transparent white */\n    backdrop-filter: blur(10px); /* Adjust the blur as needed */\n    box-shadow: none;\n  }\n  \n  /* For solid navbar */\n  .navbar-solid {\n    background: white;\n    backdrop-filter: none;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for the solid state */\n  }\n  \n  .navbar-solid,\n  .navbar-transparent {\n    position: fixed; /* Make sure the navbar stays on top */\n    top: 0;\n    left: 0;\n    right: 0;\n    z-index: 1000; /* Ensure it’s above other content */\n  }\n  \n  .navbar-transparent {\n    position: absolute; /* Allows it to overlap with other content in the background */\n  }\n  \n  /* Add some padding for better spacing */\n  header nav {\n    padding: 10px 20px; /* Adjust padding as needed */\n  }\n  \n  ul {\n    margin: 0;\n    padding: 0;\n    list-style: none;\n  }\n  \n  a {\n    text-decoration: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
