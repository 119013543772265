// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ai-green-v1 {
  position: relative;
  top: 0;
  left: 0;
}

.init-ai-section1 {
  position: absolute;
  top: 35%;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  color: white;
  font-weight: 900;
}

/* Default font size for larger screens */
.init-ai-section1:nth-child(1) {
  font-size: 4rem;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .init-ai-section1:nth-child(1) {
    font-size: 1rem; /* Adjust this value as needed */
  }
}
.init-red-bg {
  background: red;
}

.abspage {
  padding-right: 66%;
}`, "",{"version":3,"sources":["webpack://./src/pages/AboutUs/style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;AACJ;;AAEA;EACI,kBAAA;EACA,QAAA;EACA,QAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AACJ;;AAEA,yCAAA;AACA;EACI,eAAA;AACJ;;AAEA,mCAAA;AACA;EACI;IACI,eAAA,EAAA,gCAAA;EACN;AACF;AAEA;EACI,eAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ","sourcesContent":[".ai-green-v1 {\n    position: relative;\n    top: 0;\n    left: 0;\n}\n\n.init-ai-section1 {\n    position: absolute;\n    top: 35%;\n    left: 0%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    width: 100%;\n    color: white;\n    font-weight: 900;\n}\n\n/* Default font size for larger screens */\n.init-ai-section1:nth-child(1) {\n    font-size: 4rem;\n}\n\n/* Media query for mobile devices */\n@media (max-width: 768px) {\n    .init-ai-section1:nth-child(1) {\n        font-size: 1rem; /* Adjust this value as needed */\n    }\n}\n\n.init-red-bg {\n    background: red;\n}\n\n.abspage{\n    padding-right: 66%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
